@charset "UTF-8";
:root {
  --background: #0973b9;
  --color: #5e8516 ;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.dateTimePicker {
  width: 100%;
  color: black;
}

.sip_card {
  width: 200px;
  height: 300px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.24);
  padding: 20px;
  margin: 8px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.sip_card p {
  text-align: center;
}
.sip_card img {
  width: 100%;
  height: 100px;
  object-fit: contain;
  border-radius: 10px;
  margin-bottom: 30px;
}

.text-container {
  display: -webkit-box;
  -webkit-line-clamp: 6; /* Limits to 6 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background);
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide number input spinner in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Optional: Hide the number input spinner in IE */
input[type=number] {
  -ms-appearance: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 15px;
  font-family: Arial, serif;
}

body {
  margin: 0;
  overflow-x: hidden;
  background-image: url("https://ucom-acc.ats.am/images/bk.jpg");
}

.single-line-text {
  width: 118px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0;
  clear: both;
  display: inline-block;
}

.css-q34dxg {
  padding: 10px;
}

.tooltip {
  max-width: 250px;
  white-space: normal;
}

.crmBlock {
  border: 1px solid rgb(204, 204, 204);
  padding: 20px;
  width: 280px;
  background: white;
  margin: 10px 7px;
}

ul {
  padding: 0;
  margin: 0;
}

#root {
  overflow-x: hidden;
}

.header {
  width: 100%;
  background-image: url("https://ucom-acc.ats.am/images/bk.jpg");
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
}
.header p {
  margin: 0;
}
.header_border {
  width: 30px;
  height: 84px;
  background: url(https://ucom-acc.ats.am/images/border.png) top center no-repeat;
}
.header_food {
  margin-left: 5px;
  /* width: 120px; */
  height: 84px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.header_food a {
  color: #039be5;
  font-size: 17px;
  font-weight: 700;
}
.header_food a:hover {
  text-decoration: underline;
}
.header_food > p {
  width: 103px;
  height: 14px;
  /* font-family: Arian AMU Serif; */
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
}
.header span {
  text-decoration: none;
  height: 22px;
  /* font-family: Lato; */
  font-style: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
}
.header_Finance {
  /* width: 100px; */
  height: 83px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header_balance {
  margin-left: 10px;
  /* width: 120px; */
  height: 84px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  /* align-items: end; */
}
.header_balance > p {
  /* width: 126px; */
  height: 22px;
  margin: 4px 0 0 0;
  /* font-family: Lato; */
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  text-align: left;
  color: #CE405F;
}
.header_container {
  width: 100%;
  height: 83px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
.header_container .header_block {
  width: 1166px;
  height: 83px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.header_container .right {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.header_container .right .profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
.header_container .right .logout {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}
.header_container .right a {
  font-style: normal;
  font-size: 16px;
  color: #000;
}
.header_container .left {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.header_container .left .header_food a {
  color: #039be5;
}
.header_container .left a {
  font-style: normal;
  font-size: 16px;
  color: #000;
}
.header_container .left figure {
  position: relative;
  width: 135px;
  height: 84px;
  margin: 0 20px 0 0;
  display: flex;
  align-items: center;
}
.header_container .left figure .v_office {
  position: absolute;
  top: 16px;
  right: 0;
  font-size: 14px;
  font-weight: 900;
  color: var(--background);
  font-style: italic;
  cursor: pointer;
}

.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, var(--background) 92%, #0000);
  background: var(--_c) top, var(--_c) left, var(--_c) right, var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}

@keyframes l7 {
  to {
    transform: rotate(0.5turn);
  }
}
.copied {
  position: absolute;
  bottom: 76px;
  color: #898989;
  margin: auto;
  width: 100px;
  left: 0;
  right: 0;
  z-index: 11110;
  font-size: 23px;
  text-align: center;
}

.triangle_new {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 0 9px 13px;
  border-color: transparent transparent transparent var(--background);
  transform: rotate(0deg);
  position: absolute;
  right: -13px;
  z-index: 10000000;
}

.main {
  width: 1195px;
}
.main_container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.main .accordion {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}
.main .accordion-body {
  border-bottom: 1px solid #ccc;
}
.main .accordion-body,
.main .accordion-body a,
.main .accordion-button {
  transition: all 0.4s;
}
.main .accordion-button::after {
  content: none;
}
.main .accordion-button:focus {
  border: none;
  box-shadow: none;
}
.main .accordion-button:not(.collapsed) {
  background: #FFFFFF;
}
.main .accordion-header svg {
  margin-right: 10px;
}
.main .accordion-body:hover a {
  color: white;
}
.main a {
  color: #555;
  text-decoration: none;
}
.main .accordion-button {
  background: #FFFFFF;
  color: #000;
  font-weight: 400;
  text-decoration: none;
  font-family: Arial, serif;
}
.main .accordion-link.active,
.main .accordion-button.active {
  background: var(--background);
  color: white;
}
.main .accordion-button.accordion-link {
  position: relative;
  font-weight: 400;
  text-decoration: none;
  font-family: Arial, serif;
}
.main .accordion-item:first-of-type h2 button {
  background: var(--background);
  color: white;
}
.main .accordion-button.accordion-link.active,
.main .accordion-body:hover,
.main .accordion-body.active,
.main .accordion-button:hover {
  cursor: pointer;
  background: #555;
  color: white;
}
.main .accordion-button.accordion-link.active span {
  position: absolute;
  height: 100%;
  width: 5px;
  background: var(--background);
  left: 0;
  top: 0;
}

.btn_block {
  margin: 10px 16px;
  display: flex;
  justify-content: flex-end;
}
.btn_block button {
  border: 1px solid;
  padding: 5px 20px;
  color: var(--background);
  background: white;
  transition: all 0.4s;
  margin-left: 15px;
}
.btn_block button:hover {
  color: var(--color);
}

.short_block {
  overflow: auto;
}

.home_input {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  transition: all 0.4s;
}
.home_input:focus {
  border-bottom: 1px solid var(--background);
}

.search {
  position: relative;
}
.search_cover {
  width: 100%;
  position: absolute;
  transition: all 0.4s;
  height: 100%;
  left: 0;
  background: white;
  top: -100%;
}

.foot_footer a {
  font-size: 13px;
}

.footer {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.25);
  background: #FFFFFF url("https://ucom-acc.ats.am/images/bk.jpg");
}
.footer_container {
  width: 100%;
}
.footer_container div {
  height: 100%;
}
.footer_container div .langBlock {
  height: 22px;
  width: 22px;
  border-radius: 100%;
  margin-left: 11px;
}
.footer_container div .langBlock img {
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  margin: 0;
  padding: 0;
}
.footer_container div figure {
  margin: 0;
  height: 80px;
  display: flex;
  align-items: center;
}
.footer_container div figure img {
  width: 90%;
  height: 90%;
  object-fit: contain;
  padding: 10px 0;
}

.form_login_header {
  margin-bottom: 20px;
  width: 630px;
  height: 84px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.form_login_header_logo {
  width: 128.55px;
  height: 84px;
  background: url(../img/image_ucom.png) top center no-repeat;
}
.form_login_header_info {
  width: 206px;
  height: 42px;
  /* font-family: Arian AMU Serif; */
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: right;
  color: #7EBC09;
}

.select__menu {
  position: absolute;
  background: #cccccc;
}

.tableParent {
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.25);
  height: fit-content;
  margin: 10px 16px;
  overflow: auto;
  position: relative;
}
.tableParent .myTableStyle {
  min-height: 200px;
}
.tableParent > div {
  width: 100%;
  overflow: auto;
}
.tableParent .search {
  margin: 0 14px;
}
.tableParent .search input {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--background);
  transition: all 0.4s;
  padding: 7px 0;
}
.tableParent .search input:focus {
  border-bottom: 1px solid #26a69a;
  box-shadow: 0 1px 0 0 #26a69a;
}
.tableParent .add {
  color: var(--background);
  cursor: pointer;
  transition: all 0.4s;
}
.tableParent .add:hover {
  color: var(--background);
}

.delete,
.edit {
  cursor: pointer;
  font-size: 20px;
  margin: 0 16px;
  color: var(--background);
}

.ok {
  background: transparent;
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  border-bottom: 2px solid var(--background);
  transition: all 0.4s;
}
.ok:focus {
  border-bottom: 2px solid var(--background);
}

.css-1a4b21o,
.action {
  padding: 5px !important;
}

.ok.input {
  width: 90%;
  height: 40px;
}

.delete {
  color: red;
}

.taskTable {
  padding: 30px;
  overflow: auto;
}
.taskTable table, .taskTable th {
  border: 1px solid #cccccc;
  background: white;
}
.taskTable table {
  width: 100%;
  border-collapse: collapse;
}
.taskTable table tbody tr {
  transition: 0.4s;
}
.taskTable table tbody tr:hover {
  background: #e8e8e8;
}
.taskTable th, .taskTable td {
  height: 40px;
  padding: 9px 19px;
  font-size: 11px;
  min-width: 130px;
}
.taskTable .taskAction span {
  padding: 10px 20px;
  border-right: 1px solid #ccc;
  cursor: pointer;
}
.taskTable .taskAction span span {
  margin: 0 5px;
  border-right: none;
}

.auto_status {
  cursor: pointer;
}
.auto_status svg:hover {
  border-bottom: 1px solid;
}
.auto_status:hover {
  text-decoration: underline;
}

.count {
  display: flex;
  flex-direction: column;
}
.count span {
  color: #a7a7a7;
  font-size: 13px;
  margin-bottom: 13px;
}
.count input {
  background: transparent;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid #858585;
  transition: all 0.4s;
}
.count input:focus {
  border-bottom: 1px solid var(--background);
}
.count input[type=checkbox] {
  width: 13px;
  height: 13px;
}

.crm_pluses {
  margin: 10px 20px;
  list-style: none;
}
.crm_pluses li {
  font-size: 15px;
  margin: 15px 0;
  color: #473838;
}
.crm_pluses li:before {
  content: "✓";
  padding-right: 5px;
  font-weight: bold;
  color: var(--background) !important;
}

.radioSpan {
  margin: 0 14px 0 5px;
}

.checkbox_p {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
.checkbox_p span {
  margin: 0 14px 0 5px;
}

.call_settings th, .call_settings td {
  border: 1px solid #5f5f5f;
  border-collapse: collapse;
  padding: 10px;
  text-align: center;
}
.call_settings .call_check {
  margin-left: 20px;
}

.my-border {
  border-left: 1px dotted black;
  margin: 0 8px 0 8px;
}

.blog-item-content p {
  font-size: 12px;
}

.addBtnBig,
.addBtn {
  padding: 0 20px;
  border: none;
  color: white;
  background: var(--background);
  transition: all 0.4s;
  width: fit-content;
  cursor: pointer;
  height: 25px;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.addBtnBig:hover,
.addBtn:hover {
  background: var(--color);
  box-shadow: -1px 3px 9px 3px var(--background);
}

.webhook_block {
  padding: 15px;
}

.webhook {
  margin: 20px 0;
}

.crm_save_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 10px;
}

.addBtnBig {
  height: 35px !important;
  font-size: 16px;
}

.crm_select {
  padding: 6px 10px;
  outline: none;
}

.deleteBtn {
  padding: 0 29px;
  font-size: 14px;
  border: none;
  color: white;
  background: #ff1a1a;
  transition: all 0.4s;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  height: 25px;
}
.deleteBtn:hover {
  background: #a30a0a;
  box-shadow: -1px 3px 9px 3px #8f5656;
}

.flags {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 150px;
  height: 40px;
  transition: all 0.4s;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}
.flags:hover {
  background: var(--background);
  color: white;
}
.flags img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
  margin: 0 10px;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.file table {
  width: 100%;
  display: table;
}
.file table th, .file table td {
  padding: 10px 13px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border-radius: 2px;
}

.forFile {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.aside {
  position: absolute;
  z-index: 10000000;
  top: 0;
  width: 300px;
  left: 0;
  transition: all 0.4s;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

.radio-section {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

h1 {
  margin-bottom: 20px;
}

.radio-item [type=radio] {
  display: none;
}

.radio-item + .radio-item {
  margin-top: 15px;
}

.radio-item label {
  display: block;
  padding: 0 60px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  min-width: 250px;
  white-space: nowrap;
  position: relative;
  transition: 0.4s ease-in-out 0s;
}

.radio-item label:after,
.radio-item label:before {
  content: "";
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 19px;
  width: 19px;
  border: 2px solid var(--background);
  left: 20px;
  top: calc(50% - 4px);
}

.radio-item label:before {
  background: var(--background);
  height: 20px;
  width: 20px;
  left: 21px;
  top: calc(50% - 5px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}

.radio-item [type=radio]:checked ~ label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.confirmContainer,
.loginContainer {
  padding: 10px 0 16px;
  background: white;
  width: 450px;
  height: 375px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 2px 18px 0 rgba(39, 73, 18, 0.51);
  border-radius: 20px 0 0 20px;
  font-size: 14px;
  border: 1px solid var(--background);
}

.confirmContainer {
  height: 200px;
  justify-content: center;
  border-radius: 20px;
}

.loginLink {
  text-decoration: none;
  font-size: 13px;
  color: #039be5;
  transition: all 0.4s;
  margin-right: -4px;
}
.loginLink:hover {
  color: var(--background);
}

.adminLogin {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.adminLoginHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 35px;
}

.adminLoginMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
}
.adminLoginMain a {
  color: var(--background);
  text-decoration: underline;
}

#adminPass {
  text-align: center;
}

#adminPass::placeholder {
  text-align: center;
}

.adminInput {
  margin: 16px 0 19px;
}
.adminInput input {
  padding: 18px 10px;
  border: none;
  outline: none;
  align-items: center;
  border-bottom: 1px solid;
  border-radius: 10px;
  width: 271px;
  transition: all 0.4s;
  text-align: center;
  margin: 21px 0;
}
.adminInput input:focus {
  border-bottom: 1px solid #88dc00;
}
.adminInput input::placeholder {
  text-align: center;
}

.adminFormLabel {
  align-items: center;
  border: none;
  border-bottom: 1px solid;
  border-radius: 10px;
  margin: 17px 0 45px;
  padding: 0 10px;
  width: 271px;
  transition: all 0.4s;
}
.adminFormLabel:focus-within {
  border-bottom: 1px solid var(--background);
}

.loginAside {
  background: var(--background);
  height: 375px;
  border-radius: 0 20px 20px 0;
  width: 250px;
  font-size: 11px;
  color: white;
  padding: 0;
  box-shadow: 9px 0 18px 0 rgba(39, 73, 18, 0.51);
}
.loginAside > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loginAside a {
  color: #fff;
  font-size: 11px;
  margin: 3px 0;
}
.loginAside p {
  font-size: 13px;
  text-align: center;
  display: flex;
  align-items: center;
}
.loginAside .contact {
  font-weight: bold;
}
.loginAside .logoLogin {
  width: 100%;
  margin-top: 20px;
}
.loginAside .logoLogin img {
  width: 250px;
  height: 130px;
}

#adminPass {
  border: none;
  outline: none;
  width: 228px;
  padding: 10px;
}

.custom_span {
  font-size: 13px;
}

.adminLoginBtn {
  border: none;
  padding: 5px 30px;
  background: var(--background);
  color: white;
  border-radius: 4px;
  transition: all 0.4s;
  font-size: 15px;
  margin-bottom: 9px;
}
.adminLoginBtn:hover {
  box-shadow: 0 12px 30px rgba(85, 156, 70, 0.16);
  background: #589016;
}

.sip_num th {
  font-size: 11px;
  padding: 0;
  cursor: pointer;
}
.sip_num th span {
  margin-left: 5px;
  visibility: hidden;
}
.sip_num th span svg {
  font-size: 12px;
}
.sip_num th:hover span {
  visibility: visible;
}

.history_page {
  font-size: 13px;
}
.history_page span {
  min-width: 120px;
  display: inline-block;
  border-bottom: 1px solid #8bc66a;
  margin-right: 10px;
  font-size: 13px;
}
.history_page input {
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid;
}
.history_page select {
  width: 100%;
  outline: none;
  border: 1px solid #e0e0e0;
  height: 35px;
}

.manager_select {
  width: 100%;
  outline: none;
  border: 1px solid #e0e0e0;
  height: 35px;
}

.MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
  align-items: center;
  justify-content: space-between;
}

a {
  color: var(--color);
}

#ui-datepicker-div {
  z-index: 2256556 !important;
}

.ui-state-active, .ui-widget-content .ui-state-active {
  background: var(--background);
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight {
  background: var(--background);
  border-color: var(--background);
  color: #FFFFFF;
}

.content_home {
  width: 1024px;
  padding: 30px 40px 40px 40px;
  margin: 38px auto;
  border-top: 0;
  border-bottom: 0;
  position: relative;
  z-index: 1000;
  min-height: 80px;
  background-color: #fff;
  transition: box-shadow 0.25s;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.crm_img img {
  transition: all 0.4s;
}

.crm_img.active,
.crm_img:hover {
  transition: all 0.4s;
}
.crm_img.active img,
.crm_img:hover img {
  filter: invert(92%) sepia(31%) saturate(2%) hue-rotate(230deg) brightness(110%) contrast(101%);
}

.urs_table table tr {
  border-bottom: 1px solid #d1d5d9;
}
.urs_table table td, .urs_table table th, .urs_table table a {
  color: black;
  font-size: 12px;
  padding: 9px 3px;
}

.search_block input {
  border-radius: 4px;
  padding-left: 5px;
  margin-right: 20px;
  outline: none;
  width: 165px;
}

.test_mode {
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.comment_count {
  top: -15px;
  display: inline-block;
  color: #006cfe;
  padding: 1px 2px;
  left: 18px;
  border-radius: 10px;
  font-size: 13px;
}

.css-1q1u3t4-MuiTableRow-root td {
  padding: 6px !important;
  font-size: 13px;
}

.td_notes {
  max-width: 12ch;
  overflow: hidden;
  text-overflow: ellipsis;
}

.crm_input {
  border: none;
  border-bottom: 1px solid black;
  transition: 0.4s;
  outline: none;
  margin: 10px;
  border-radius: 4px;
  padding: 5px;
}
.crm_input:focus {
  border-bottom: 1px solid #4c4599;
}

.crm_single_block {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crm_single_block .addBtn {
  padding: 0 16px;
  margin: 5px;
}
.crm_single_block .addBtn.edit {
  background: var(--background);
  font-size: 15px;
}
.crm_single_block .addBtn.edit:hover {
  box-shadow: -1px 3px 9px 3px #a0ee87;
}
.crm_single_block .addBtn.del {
  background: red;
}
.crm_single_block .addBtn.del:hover {
  box-shadow: -1px 3px 9px 3px #ee9c87;
}

.sip_content div {
  margin: 5px 0;
}
.sip_content div b {
  margin-right: 5px;
}

.telegram_icon {
  display: inline-block;
  background: black;
  color: white;
  margin: 0 20px;
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
}

.telegram_block {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create_input {
  border: 1px solid #ccc;
  padding: 10px;
  outline: none;
}

.history-view .history-menu[data-v-725a497c] {
  grid-area: 1/1/2/2;
}

.history-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: var(--contentWidthMin);
  max-width: 1268px;
  height: calc(var(--tabPaddingTopAndBottom) * 2 + var(--menuWideLineHeight));
  min-height: calc(var(--tabPaddingTopAndBottom) * 2 + var(--menuWideLineHeight));
  margin-bottom: 16px;
  box-shadow: inset 0 -1px 0 0 var(--divider);
}

.itl-tabs[data-v-64d46774] {
  display: flex;
}

.itl-tabs[data-v-64d46774].mine {
  background: #f1faff;
  margin: 0 10px;
  border-radius: 5px;
}
.itl-tabs[data-v-64d46774].mine .tab-item[data-v-3ab865ea]:first-child {
  border-radius: 5px 0 0 5px;
}
.itl-tabs[data-v-64d46774].mine .tab-item[data-v-3ab865ea]:last-child {
  border-radius: 0 5px 5px 0;
}
.itl-tabs[data-v-64d46774].mine .tab-item[data-v-3ab865ea].active {
  background: #4c4599;
  color: white;
}

.tab-item.primary[data-v-3ab865ea]:not(:last-child) {
  margin-right: var(--headerItemsMargin);
}

.tab-item[data-v-3ab865ea] {
  position: relative;
  padding: 0 13px;
}

.tab-item .input[data-v-3ab865ea] {
  display: none;
}

.tab-item.primary .tab[data-v-3ab865ea] {
  display: flex;
  align-items: center;
  padding: 5px 18px;
  margin: 0 5px;
  font-size: 15px;
  transition: all 0.4s;
  border-bottom: 1px solid transparent;
}

.tab-item.primary .tab[data-v-3ab865ea]:hover {
  border-bottom-color: #ccc;
}

.tab-item.primary.active .tab[data-v-3ab865ea] {
  border-bottom-color: #4c4599;
}

.tab-item .tab[data-v-3ab865ea] {
  cursor: pointer;
}

.tab-item.primary .tab .label[data-v-3ab865ea] {
  display: inline-block;
  padding: 7px 1px;
  color: var(--tabPrimaryTextColorDefault);
  transition: var(--fastTransition);
}

.itl-export-print[data-v-bfdb1fa6] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 16px;
}

.itl-export-print .itl-svg[data-v-bfdb1fa6] {
  color: var(--iconColorDefault);
  cursor: pointer;
}

.itl-export-print .itl-svg[data-v-bfdb1fa6]:last-child {
  margin-left: 16px;
}

.history-toolbar[data-v-4b1795d7] {
  position: sticky;
  top: 0;
  z-index: 3;
  border-top-left-radius: var(--sectionBorderRadius);
  border-top-right-radius: var(--sectionBorderRadius);
  background-color: var(--white);
}

.itl-toolbar[data-v-239d7f3e]:not(.with-more) {
  justify-content: center;
  max-height: 140px;
}

.itl-toolbar[data-v-239d7f3e] {
  width: 100%;
  padding: 0 16px;
  border-top-left-radius: var(--sectionBorderRadius);
  border-top-right-radius: var(--sectionBorderRadius);
  background-color: var(--white);
}

.history-toolbar .itl-chips[data-v-4b1795d7] {
  padding: 16px 16px 0 16px;
}

.itl-chips[data-v-0b9c1e43] {
  display: flex;
  justify-content: space-between;
}

.itl-toolbar .itl-toolbar-item[data-v-239d7f3e] {
  display: flex;
  justify-content: center;
  min-height: 40px;
}

.itl-toolbar-item[data-v-0558cee0] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-right: var(--defaultToolbarItemMarginRight);
}

.itl-toolbar-item .header[data-v-0558cee0] {
  color: var(--mist30);
  font-weight: var(--tableHeaderFontWeight);
  font-size: var(--tableHeaderFontSize);
  font-family: var(--tableHeaderFontFamily);
  line-height: var(--tableHeaderLineHeight);
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  margin-bottom: 8px;
  background: none;
  width: auto;
  box-shadow: none;
}

.with-divider.divider-transitioned.divider-target-toolbar-more:not(.divider-visible) {
  transition: margin-bottom var(--slowTransition), padding-bottom 0.1s var(--basicTransition);
}

.itl-toolbar-wrapper[data-v-239d7f3e] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.with-divider {
  position: relative;
}

.itl-calendar[data-v-c9f80a70] {
  min-width: 188px;
  max-width: 188px;
}

.itl-custom-combobox[data-v-013e8b40] {
  width: 100%;
  max-width: 296px;
}

.itl-custom-combobox .wrapper[data-v-013e8b40]:hover {
  color: var(--mist80);
}

.itl-custom-combobox .wrapper[data-v-013e8b40]:hover {
  border-color: #4c4599;
}

.itl-custom-combobox .wrapper[data-v-013e8b40] {
  border-color: #cccccc;
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  transition: all 0.4s;
}

.itl-custom-combobox .wrapper .custom-combobox-btn[data-v-013e8b40] {
  color: var(--mist80);
  font-weight: var(--inputRegularFontWeight);
  font-size: var(--inputRegularFontSize);
  font-family: var(--inputRegularFontFamily);
  line-height: var(--inputRegularLineHeight);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--inputBorderRadius);
  background-color: var(--white);
  outline: none;
  text-align: left;
  cursor: pointer;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

.itl-custom-combobox .wrapper .custom-combobox-btn .label-wrapper[data-v-013e8b40] {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 32px);
  max-width: 262px;
  padding: 7px 0 7px 7px;
  font-size: 14px;
}

.tab-item .input[data-v-3ab865ea] {
  display: none;
}

.tab-item.secondary .input:checked + .tab[data-v-3ab865ea], .tab-item.secondary .input:checked + .tab[data-v-3ab865ea]:hover, .tab-item.secondary .input:checked + .tab[data-v-3ab865ea]:active {
  border-color: var(--tabSecondaryBorderColorActive);
}

.tab-item.secondary .input:checked + .tab[data-v-3ab865ea] {
  position: relative;
  z-index: 1;
  border-color: var(--tabSecondaryBgColorActive);
  background-color: var(--tabSecondaryBgColorActive);
  box-shadow: var(--tabSecondaryShadowActive);
  transition: none;
}

.tab-item.secondary:not(:last-of-type) .tab[data-v-3ab865ea] {
  margin-right: var(--tabSecondaryMargin);
  border-right: none;
}

.tab-item.secondary:first-of-type .tab[data-v-3ab865ea] {
  border-top-left-radius: var(--tabBorderRadius);
  border-bottom-left-radius: var(--tabBorderRadius);
}

.tab-item.secondary .tab.fixed-width[data-v-3ab865ea] {
  max-width: 175px;
}

.tab-item.active:not(.clickable-when-active) .tab[data-v-3ab865ea] {
  cursor: default;
}

.tab-item.secondary .tab[data-v-3ab865ea] {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  height: 40px;
  border: solid 1px var(--tabSecondaryBorderColorDefault);
}

.tab-item .tab[data-v-3ab865ea] {
  cursor: pointer;
}

.tab-item.secondary .tab .label.with-accessory-text[data-v-3ab865ea]:not(.with-icon) {
  padding-left: 11px;
}

.tab-item.secondary .tab .label[data-v-3ab865ea] {
  display: grid;
  padding: 8px 8px 7px 7px;
  font-size: 14px;
}

.tab-item .tab .label[data-v-3ab865ea], .tab-item .tab .accessory-text[data-v-3ab865ea] {
  color: var(--tabPrimaryTextColorDefault);
  white-space: nowrap;
  font-size: 14px;
}

.itl-button.itl-button-icon[data-v-82c7c586] {
  padding-left: 8px;
}

.itl-button.primary[data-v-82c7c586], .itl-button.secondary[data-v-82c7c586], .itl-button.danger[data-v-82c7c586] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 328px;
}

.itl-button.secondary[data-v-82c7c586] {
  border: 1px solid var(--btnSecondaryBorderColorDefault);
  background-color: var(--btnSecondaryBgColorDefault);
  color: var(--btnSecondaryTextColorDefault);
  box-shadow: var(--btnSecondaryShadow);
}

.itl-button[data-v-82c7c586]:last-of-type {
  margin-right: 0;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 0;
}

.itl-button[data-v-82c7c586] {
  color: var(--mist80);
  font-weight: var(--btnFontWeight);
  font-size: var(--btnFontSize);
  font-family: var(--btnFontFamily);
  letter-spacing: var(--btnLetterSpacing);
  text-transform: var(--btnTextTransform);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 104px;
  height: 40px;
  margin-right: 8px;
  padding: var(--btnPadding);
  border-radius: var(--btnBorderRadius);
  outline: none;
  line-height: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  transition: var(--basicTransition);
  transition-property: background-color, border, color, box-shadow;
}

.itl-toolbar-wrapper .right-side[data-v-239d7f3e] {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
  min-height: 40px;
}

.itl-toolbar-item .itl-toolbar-item[data-v-0558cee0], .itl-toolbar-item.align-right[data-v-0558cee0] {
  margin-right: 0;
}

.align-right {
  margin-left: auto;
}

.itl-search[data-v-06e5edcb] {
  position: relative;
  display: inline-block;
  width: 175px;
  min-height: 40px;
}

.itl-search[data-v-06e5edcb] .itl-combobox-new .wrapper {
  width: inherit;
}

.itl-combobox-new .wrapper.active[data-v-2993ab1c] {
  background-color: var(--inputBgColorActive);
}

.itl-combobox-new .wrapper[data-v-2993ab1c]:active, .itl-combobox-new .wrapper.active[data-v-2993ab1c] {
  border-color: var(--inputBorderColorActive);
}

.itl-combobox-new .wrapper[data-v-2993ab1c] {
  color: black;
  font-weight: var(--inputRegularFontWeight);
  font-size: 14px;
  font-family: var(--inputRegularFontFamily);
  line-height: var(--inputRegularLineHeight);
  border-color: var(--inputBorderColorDefault);
  position: relative;
  display: block;
  width: 296px;
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: 5px;
  background-color: var(--inputBgColorDefault);
  transition: var(--slowTransition);
}

.itl-combobox-new .wrapper .input[data-v-2993ab1c] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 38px;
  border: none;
  border-radius: inherit;
  background-color: var(--inputBgColorDefault);
  cursor: pointer;
}

.itl-search[data-v-06e5edcb] .itl-combobox-new .wrapper .input input.search {
  padding-right: 39px;
  color: black;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  opacity: 1;
}

.itl-combobox-new .wrapper .input .search.searchable[data-v-2993ab1c] {
  cursor: text;
}

.itl-combobox-new .wrapper .input .search[data-v-2993ab1c] {
  color: black;
  font-weight: var(--inputRegularFontWeight);
  font-size: var(--inputRegularFontSize);
  font-family: var(--inputRegularFontFamily);
  line-height: var(--inputRegularLineHeight);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  height: 38px;
  padding: 7px;
  border: none;
  border-radius: inherit 0 0 inherit;
  background-color: inherit;
  word-break: normal;
  cursor: pointer;
}

.itl-combobox-new .wrapper .items.position-above[data-v-2993ab1c] {
  bottom: calc(100% + 1px);
  z-index: 48;
  box-shadow: var(--shadowElevatedHeavy);
}

.itl-combobox-new .wrapper .items.hidden[data-v-2993ab1c] {
  display: none;
}

.itl-combobox-new .wrapper .items[data-v-2993ab1c] {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  left: -1px;
  z-index: 51;
  flex-direction: column;
  overflow-y: auto;
  width: calc(100% + 2px);
  margin-top: -1px;
  border: var(--dropdownBorderWidth) solid var(--dropdownBorderColor);
  border-radius: var(--inputBorderRadius);
  background: var(--white);
  transition: var(--fastTransition);
  transition-property: background-color, color, opacity;
}

input {
  outline: none;
}

.itl-combobox-new .text-hint[data-v-2993ab1c] {
  margin-top: 8px;
  color: var(--comboboxItemTextColorDefault);
}

.itl-combobox-new .text[data-v-2993ab1c] {
  color: var(--mist80);
  font-weight: var(--markFontWeight);
  font-size: var(--markFontSize);
  font-family: var(--markFontFamily);
  line-height: var(--markLineHeight);
  width: 100%;
}

.itl-search .empty-search[data-v-06e5edcb] {
  cursor: default;
}

.itl-search .search-icon[data-v-06e5edcb] {
  color: var(--inputIconColorDefault);
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 48;
  display: inline-block;
  max-width: 32px;
  max-height: 32px;
}

.itl-svg {
  line-height: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.asideHeader,
.asideHeaderBack {
  top: 0;
  right: -100%;
  width: 300px;
  height: 100vh;
  background: white;
  z-index: 100;
  padding: 20px;
  transition: all 0.4s;
  overflow: auto;
}
.asideHeader select,
.asideHeaderBack select {
  outline: none;
  padding: 10px;
  margin: 5px;
  transition: all 0.4s;
  cursor: pointer;
  border-color: #e4e4e7;
  border-radius: 5px;
}
.asideHeader select:hover,
.asideHeaderBack select:hover {
  border-color: #b2ceff;
}
.asideHeader.seen,
.asideHeaderBack.seen {
  right: 0;
}

.asideHeaderBack {
  width: 100%;
  background: rgba(0, 0, 0, 0.46);
}

.itl-custom-combobox .wrapper .custom-combobox-dropdown .body[data-v-013e8b40] {
  overflow-y: auto;
  width: 100%;
  max-height: 392px;
}

.itl-calendar[data-v-c9f80a70] .body {
  border-radius: inherit;
}

.itl-calendar .calendar-periods[data-v-c9f80a70] {
  border-radius: inherit;
}

.itl-calendar .calendar-periods .calendar-list[data-v-c9f80a70] {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  height: 302px;
  border-radius: inherit;
  background-color: #fff;
}

.itl-calendar .calendar-periods .calendar-list .calendar-item[data-v-c9f80a70]:first-of-type {
  border-top-left-radius: var(--inputBorderRadius);
  border-top-right-radius: var(--inputBorderRadius);
}

.itl-calendar .calendar-periods .calendar-list .calendar-item[data-v-c9f80a70] {
  position: relative;
  width: 100%;
  min-width: 100%;
  padding: 8px 8px 8px 47px;
  list-style-type: none;
  cursor: pointer;
}

.itl-calendar .calendar-periods .calendar-list .calendar-item div[data-v-c9f80a70] {
  max-width: 120px;
  display: block;
}

.itl-calendar .calendar-periods .calendar-list .calendar-item[data-v-c9f80a70] {
  position: relative;
  width: 100%;
  min-width: 100%;
  padding: 8px 8px 8px 47px;
  list-style-type: none;
  cursor: pointer;
  font-size: 14px;
}
.itl-calendar .calendar-periods .calendar-list .calendar-item[data-v-c9f80a70]:hover {
  background: #cccccc;
}

.itl-calendar .calendar-periods .calendar-list .calendar-item .active[data-v-c9f80a70] {
  position: absolute;
  top: 8px;
  left: 16px;
  color: var(--comboboxItemIconColor);
}

.itl-calendar .calendar-periods .calendar-list .calendar-item div[data-v-c9f80a70] {
  max-width: 120px;
}

.itl-custom-combobox .wrapper .custom-combobox-dropdown.position-below[data-v-013e8b40] {
  top: calc(100% + 1px);
  box-shadow: 0 15px 30px 0 rgba(73, 86, 93, 0.1215686275);
}

.itl-custom-combobox .wrapper .custom-combobox-dropdown.position-right[data-v-013e8b40] {
  left: -1px;
}

.itl-custom-combobox .wrapper .custom-combobox-dropdown[data-v-013e8b40] {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% + 2px);
  min-height: -moz-min-content;
  min-height: min-content;
  border: var(--dropdownBorderWidth) solid var(--dropdownBorderColor);
  border-radius: var(--inputBorderRadius);
  background: var(--white);
  transition: var(--fastTransition);
  transition-property: opacity;
}

.filter__item {
  display: flex;
  border: 1px solid #7bd3fd;
  flex-direction: row;
  width: fit-content;
  padding: 5px 10px;
  margin: 20px 5px 0 0;
  font-size: 16px;
  border-radius: 5px;
  background: aliceblue;
  cursor: pointer;
}
.filter__item span:last-child {
  margin-left: 5px;
}
.filter__item.clean_filter {
  border: none;
  background: #FFFFFF;
  color: #7b91fd;
}

.button-edge__label {
  position: absolute;
  pointer-events: all;
  transform-origin: center;
}

.button-edge__button {
  width: 30px;
  height: 30px;
  border: 5px solid #f7f9fb;
  color: var(--xy-edge-node-color-default);
  background-color: #f3f3f4;
  cursor: pointer;
  border-radius: 50%;
  font-size: 12px;
  padding-top: 0;
}

.button-edge__button:hover {
  background-color: var(--xy-theme-hover);
  color: #ffffff;
}

.react-flow__edge-textbg {
  fill: #f7f9fb;
}

.select__indicator-separator.css-1u9des2-indicatorSeparator {
  border: none;
  background: none;
  min-width: auto;
}

.control {
  padding: 10px 30px;
}
.control div {
  font-size: 15px;
}

.inner {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 1px 6px 4px #ccc;
  /* audio image */
  /* controls */
  /* time */
  /* Top bar */
  /* Input range - firefox */
  /* played progress length - Chrome & safari*/
  /* played progress length - firefox */
  /* slider thumb - chrome and safari */
  /* dragging thumb - chrome and safari */
  /* slider thumb - firefox */
  /* dragging thumb - firefox */
}
.inner .audio-info {
  display: flex;
  gap: 20px;
}
.inner .text {
  flex-direction: row;
  display: flex;
  color: black;
}
.inner .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.inner .audio-image {
  width: 300px;
  height: 300px;
  background: #1e1e1e;
}
.inner .audio-icon {
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #989898;
}
.inner .audio-image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.inner .controls-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.inner .controls button, .inner .volume button {
  border: none;
  background-color: transparent;
  margin-right: 8px;
  cursor: pointer;
}
.inner button svg {
  font-size: 20px;
}
.inner .volume {
  display: flex;
  align-items: center;
}
.inner .volume button {
  margin: 0;
}
.inner .progress {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: white;
}
.inner .time {
  color: #333;
  font-size: 13px;
  line-height: 46px;
}
.inner .time.current {
  color: #7b91fd;
}
.inner .top__bar {
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f2;
}
.inner .top__bar a {
  color: inherit;
}
.inner .top__bar p {
  margin: 0;
}
.inner input[type=range] {
  --range-progress: 0;
  -webkit-appearance: none;
  position: relative;
  background: #ccc;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  cursor: pointer;
  margin: 12px;
}
.inner input[type=range]::-moz-range-track {
  position: relative;
  background: #ccc;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  cursor: pointer;
}
.inner input[type=range]::before {
  content: "";
  height: 4px;
  background: #7b91fd;
  width: var(--range-progress);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
}
.inner input[type=range]::-moz-range-progress {
  background: #7b91fd;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 4px;
}
.inner input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  border: none;
  background-color: #7b91fd;
  cursor: pointer;
  position: relative;
}
.inner input[type=range]:active::-webkit-slider-thumb {
  transform: scale(1.2);
}
.inner input[type=range]::-moz-range-thumb {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #7b91fd;
  cursor: pointer;
  border: transparent;
  position: relative;
}
.inner input[type=range]:active::-moz-range-thumb {
  transform: scale(1.2);
}
.inner .progress {
  align-items: center;
}

.truncate {
  display: inline-block;
  max-width: 10ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.app_img {
  width: 24px !important;
  margin: 2px !important;
}

.app_img_figure {
  display: flex;
  justify-content: center;
  border: 1px solid;
  align-items: center;
  margin: 1px 0;
  padding: 6px;
  border-radius: 10px;
  background: white;
  cursor: pointer;
  transition: all 0.4s;
}

.app_img_figure:hover {
  box-shadow: 0 -2px 20px 1px white;
}

.app_img_figure figcaption {
  color: black;
  font-size: 12px;
  margin-left: 9px;
  cursor: pointer;
}

.adminFormLabel {
  position: relative;
}
.adminFormLabel .count_pass {
  font-size: 10px;
  position: absolute;
  right: 10px;
  bottom: 2px;
}

.hasDatepicker {
  outline: none;
  margin: 0 10px;
  width: 105px;
}

@media screen and (max-width: 990px) {
  .sidenav {
    padding-top: 15px;
  }
  .sip_num td, .sip_num th {
    font-size: 12px;
    padding: 14px 5px;
  }
  .sip_header th, .sip_header td {
    font-size: 16px;
    padding: 10px 20px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
@media screen and (max-width: 565px) {
  .main {
    padding: 0;
  }
  .header_container .left figure .v_office {
    position: absolute;
    top: 10px;
    right: -15px;
    font-size: 11px;
    font-weight: 900;
    color: var(--background);
    font-style: italic;
    cursor: pointer;
  }
  .tableParent {
    margin: 10px;
  }
  .tableParent div .MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel {
    display: none;
  }
  .tableParent div .MuiInputBase-root.MuiInputBase-colorPrimary.MuiTablePagination-input.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin: 0;
  }
  .adminLogin {
    flex-direction: column;
  }
  .header_container .left figure {
    width: 80px;
  }
  .header_container .left figure img {
    width: 80px;
  }
  .sip_num td, .sip_num th {
    font-size: 10px;
    padding: 14px 5px;
  }
  .sip_header th, .sip_header td {
    font-size: 12px;
    padding: 10px 20px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .loginAside {
    width: 315px;
    height: 325px;
    border-radius: 0 0 20px 20px;
  }
  .loginAside .logoLogin img {
    width: 250px;
    height: 85px;
  }
  .confirmContainer,
  .loginContainer {
    width: 315px;
    border-radius: 20px 20px 0 0;
  }
  .confirmContainer .adminFormLabel,
  .confirmContainer input,
  .loginContainer .adminFormLabel,
  .loginContainer input {
    width: 215px;
  }
  .confirmContainer #adminPass,
  .loginContainer #adminPass {
    width: 170px;
  }
  .confirmContainer {
    border-radius: 20px;
  }
  .form_login_header_info {
    font-size: 30px;
  }
  .form_login_header {
    margin-bottom: 20px;
    width: 310px;
  }
}
.online_span {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.online_span hr {
  width: 100%;
}

.crm_title {
  margin: 20px 0;
}
.crm_title span {
  font-size: 18px;
  font-weight: bold;
}

.crm_paragraph {
  margin-left: 20px;
}

.custom_node {
  background: #FFFFFF;
  border-radius: 5px;
  border: 1px solid;
  padding: 0 20px;
}

